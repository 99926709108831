import React, { useEffect, useState } from "react";

import Homepage from "../../components/Homepage/Homepage";

const API_URL = process.env.GATSBY_STRAPI_URL || "http://localhost:1337";

const PreviewHomePage = ({ data }) => {
  const [amxConnectHeading, setAmxConnectHeading] = useState();
  const [panelData, setPanelData] = useState();
  const [featurePanelData, setFeaturePanelData] = useState();
  const [heroData, setHeroData] = useState();
  const [insightsData, setInsightsData] = useState();
  const [workingWithData, setWorkingWithData] = useState();

  useEffect(() => {
    const homepagePromise = fetch(`${API_URL}/homepage`).then((response) =>
      response.json()
    );

    const partnersPromise = fetch(`${API_URL}/partners`).then((response) =>
      response.json()
    );

    Promise.all([homepagePromise, partnersPromise]).then(
      ([homepageData, partnersData]) => {
        setAmxConnectHeading(homepageData.amxConnectHeading);
        setPanelData(homepageData.amxConnectPanels);

        setFeaturePanelData({
          copy: homepageData.learnMore.copy,
          cta: homepageData.learnMore.cta,
          heading: homepageData.learnMore.heading,
          headerImage: homepageData.learnMore.headerImage.url,
        });

        setHeroData({
          bannerImage: homepageData.hero.image.formats.large.url,
          video: homepageData.hero.popupVideo.video.url,
          bkgVideo: homepageData.hero.backgroundVideo.url,
          transcript: homepageData.hero.popupVideo.transcript.url,
          headings: homepageData.hero.headings,
          ctaText: homepageData.hero.ctaText,
        });

        setInsightsData(homepageData.Articles);

        setWorkingWithData({
          heading: homepageData.workingWithHeading,
          images: partnersData.map((partner) => (
            <img
              key={partner.logo.url}
              src={partner.logo.url}
              alt={partner.logo.alternativeText}
            />
          )),
        });
      }
    );
  }, []);

  return amxConnectHeading &&
    featurePanelData &&
    heroData &&
    insightsData &&
    panelData &&
    workingWithData ? (
    <Homepage
      amxConnectHeading={amxConnectHeading}
      featurePanelData={featurePanelData}
      heroData={heroData}
      articlesData={insightsData}
      panelData={panelData}
      workingWithData={workingWithData}
    />
  ) : (
    <p>Loading homepage preview data...</p>
  );
};

export default PreviewHomePage;
